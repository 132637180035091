class Pin {
    constructor(el, onOpen) {
        this.onOpen = onOpen;
        this.body = el;
        this.pinBtn = this.body.querySelector(".c-pin__item");
        this.closeBtn = this.body.querySelector(".c-pin__close");
        this.popup = this.body.querySelector(".c-pin__popup");
        this.isOpened = !this.body.classList.contains("c-pin_closed");

        this.pinBtn.addEventListener("click", this.pinBtnClickHandler.bind(this));
        this.closeBtn.addEventListener("click", this.closeBtnClickHandler.bind(this));
    }
    
    open() {
        this.onOpen();
        this.body.classList.remove("c-pin_closed");
        this.isOpened = true;
    }
    
    close() {
        this.body.classList.add("c-pin_closed");
        this.isOpened = false;
    }

    closeBtnClickHandler(e) {
        e.preventDefault();
        this.close();
    }

    pinBtnClickHandler(e) {
        e.preventDefault();
        if(this.isOpened) {
            this.close();
        }
        else {
            this.open()
        }
    }
}