class PhotoField {

    constructor() {
        var files = document.querySelectorAll(".photo-field");

        Array.prototype.forEach.call(files, function (item) {
            var input = item.querySelector(".photo-field__el");
            var filename = item.querySelector(".photo-field__filename");

            input.addEventListener("change", function () {
                filename.innerHTML = input.value.split("\\").splice(-1).join();
                item.classList.remove("photo-field_unloaded");
            });
        });
    }
}