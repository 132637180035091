class StoryMap extends Map {
    constructor() {
        super(document.querySelector(".story-map"));

        this.setZoom(parseInt(this.context.getAttribute("data-zoom"), 10));
        
        this.setCenter({
            lat: parseFloat(this.context.getAttribute("data-center-lat")),
            lng: parseFloat(this.context.getAttribute("data-center-lng"))
        });
        
        this.fetchPoints();
        
        this.mainWindow = this.context.querySelector(".story-map__main");
        this.openerBtn = this.context.querySelector(".story-map__opener");
        this.isOpened = !this.mainWindow.classList.contains("story-map__main_closed");
        
        if(this.openerBtn) {
            this.openerBtn.addEventListener("click", this.openerBtnClickHandler.bind(this));
        }
    }
    
    init() {
        super.init();
        this.addAllPoints();
    }
    
    fetchPoints() {
        let pointElements = this.context.querySelectorAll(".c-story-point");
        this.points = Array.prototype.map.call(pointElements, this.initPoint.bind(this));
    }

    initPoint(element) {
        return new Point(element, this.displayPopup.bind(this));
    }
    
    addAllPoints() {
        Array.prototype.forEach.call(this.points, (point) => {
            this.addPoint(point)
        });
    }

    openerBtnClickHandler(e) {
        e.preventDefault();
        if(this.isOpened) {
            this.close();
        }
        else {
            this.open();
        }
    }
    
    open() {
        this.mainWindow.classList.remove("story-map__main_closed");
        switch (this.engine) {
            case this.GOOGLE_ENGINE:
                setTimeout(() => google.maps.event.trigger(this.map, "resize"), 200);
                break;

            case this.YANDEX_ENGINE:
                setTimeout(() => this.map.container.fitToViewport(), 200);
                break;
        }
        
        this.isOpened = true;
    }
    
    close() {
        this.mainWindow.classList.add("story-map__main_closed");
        this.isOpened = false;
    }
    
    
}