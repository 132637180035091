class Point {
    constructor(element, mapPopup) {
        this.element = element;
        this.fetchPosition();
        this.fetchData();
        this.mapPopup = mapPopup;
    }
    
    clickHandler() {
        this.mapPopup(this);
    }
    
    fetchPosition() {
        this.position = {
            lat: parseFloat(this.element.getAttribute("data-lat")),
            lng: parseFloat(this.element.getAttribute("data-lng"))
        };
    }
    
    fetchData() {
        this.url = this.element.getAttribute("data-url");
        this.description = this.element.getAttribute("data-description");
        this.photo = this.element.getAttribute("data-photo");
        this.title = this.element.innerHTML;
    }
}