function outerWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
}



class HowToSlider {
    constructor() {
        this.currentSlide = 0;
        this.body = document.querySelector(".js-slider");
        this.container = this.body.querySelector(".js-slider-container");
        this.items = this.container.querySelectorAll(".js-slider-item");
        this.nextBtn = this.body.querySelector(".js-slider-next-btn");
        this.prevBtn = this.body.querySelector(".js-slider-prev-btn");

        //this.nextBtn.addEventListener("click", this.nextBtnHandler.bind(this));
        //this.prevBtn.addEventListener("click", this.prevBtnHandler.bind(this));
        
        this.lory = lory(this.body, {
            infinite: false,
            enableMouseEvents: true,
            classNameFrame: "js-slider-frame",
            classNameSlideContainer: "js-slider-container",
            classNamePrevCtrl: "js-slider-prev-btn",
            classNameNextCtrl: "js-slider-next-btn",
        });
    }
}