class HistoryMap {
    constructor() {
        this.element = document.querySelector(".js-history-map");
    
        this.YANDEX_ENGINE = 1;
        this.GOOGLE_ENGINE = 2;
        
        this.engine = this.YANDEX_ENGINE;
    }

    init() {
        
        switch (this.engine) {
            case this.YANDEX_ENGINE:
                this._initYandexEngine();
                return;
            
            case this.GOOGLE_ENGINE:
            default:
                this._initGoogleEngine();
                return;
                
            
        }
    }
    
    _initYandexEngine() {
        var myMap = new ymaps.Map('map', {
                center: [55.751574, 37.573856],
                zoom: 9
            }, {
                searchControlProvider: 'yandex#search'
            });
        
            myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                hintContent: 'Собственный значок метки',
                balloonContent: 'Это красивая метка'
            }, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: 'images/myIcon.gif',
                // Размеры метки.
                iconImageSize: [30, 42],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-3, -42]
            });

        myMap.geoObjects.add(myPlacemark);
    }

    _initGoogleEngine() {
        this.map = new google.maps.Map(this.element, {
            center: {lat: 54.393282, lng: 47.255090},
            scrollwheel: false,
            zoom: 4
        });

        this.pinBlueIcon = {
            url: "/css/images/pin-blue.png",
            size: new google.maps.Size(50, 75),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 75)
        };

        this.pinDarkIcon = {
            url: "/css/images/pin-dark.png",
            size: new google.maps.Size(50, 75),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 75)
        };

        for (let i = 0; i < this.places.length; i++) {
            this.places[i].marker = new google.maps.Marker({
                position: this.places[i].position,
                map: this.map,
                title: this.places[i].address,
                animation: google.maps.Animation.DROP,
                icon: this.pinBlueIcon
            });

            this.places[i].marker.addListener("click", this.markerClickHandler.bind(this, i));
        }
    }

    markerClickHandler(index, e) {
        location.href = this.places[index].link;
    }

    setData(data) {
        this.places = data;
    }
}